import React, { useState, useEffect, useContext } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import "./css/cart-pages.scss";
import OfferIceon from "./img/offer.svg";
import { PaymentSuccess } from "./popup";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import FKplus from "./img/fkplus.svg";
import FKplusBG from "./img/plus.svg";
import Select from "react-select";
import { Link } from "react-router-dom";
import { CheckoutData } from "./checkout";
import { OrderFlow } from "../../App";
import { removeDiscount } from "../../actions/menu/offerAction";
import { PlaceCardStatus } from "../../actions/checkout/placeorderStatusAction";
import { useSelector, useDispatch } from "react-redux";
import {
  PaymentRequestButtonElement,
  CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { CheckoutContext } from "./checkout";

const Cart = () => {
  const { getOrderMode } = OrderFlow();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const {
    stripepayment,
    deliveryCharge,
    bagCharge,
    serviceCharge,
    setFinalTotal,
    finalTotal,
  } = CheckoutData();
  const {
    setPaymentMId,
    setPayType,
    placeOrderApiJson,
    setPaySubmit,
    paymentType,
    paySubmit,
    walletstatus,
    setCardErrorMessage,
    setFaildShow,
    buttonstatus,
    setButtonstatus,
    finalTotalValue,
    setFinalTotalValue,
    paymentRequest,
    updateJsonData,
    setPaymentMethodId,
    setPaymentRequest,
    setGpaySubmit,
    setPaymentType,
    footerBtn,setFooterBtn,
    scrollToSection
  } = useContext(CheckoutContext);
  const [parsedDiscount, setParsedDiscount] = useState("");
  const [discountStatus, setDiscountStatus] = useState("");
  const [paymentLoad,setPaymentLoad]=useState(false);
  const subTotal = localStorage.getItem("totalPrice");
  const roundedValue = localStorage.getItem("roundOffPrice");
  const driverTipValue = localStorage.getItem("driverTipValue");

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const loginAddress = localStorage.getItem("loginAddress") || "";
  const guestAddress = localStorage.getItem("guestAddress") || "";
  const { setAddressPopupshow } = useContext(CheckoutContext);
  const createPaymentRequest = async (amount) => {
 
  
    if (!stripe || !elements) {
      return;
    }
    if (
      stripe &&
      elements 
    ) {
      const pr = await stripe.paymentRequest({
        country: "US",
        currency: "gbp",
        total: {
          label: stripepayment?.message?.stripe?.url || "FK",
          amount: Math.round(amount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API first.
      const result = await pr.canMakePayment();
      if (result) {
        setPaymentRequest(pr);
      }
    }
  };
  useEffect(() => {
    if (finalTotalValue && finalTotalValue !== "") {
      createPaymentRequest(finalTotalValue);
    }
  }, [finalTotalValue, stripe, elements]);

  useEffect(()=>{
    setPaymentLoad(true);
  },[])
  useEffect(() => {
    let timer;
    if (!paymentRequest) {
      // Show the spinner for a maximum of 3 second
      setPaymentLoad(true);

      // After 1 second, hide the spinner if payment request is still not available
      timer = setTimeout(() => {
        setPaymentLoad(false);
      }, 3000);
    } else {
      // If payment request arrives before 1 second, clear the timer and hide the spinner immediately
      clearTimeout(timer);
      setPaymentLoad(false);
    }

    // Clean up the timer when component unmounts or payment request changes
    return () => {
      clearTimeout(timer);
    };
  }, [paymentRequest]);



  useEffect(() => {
    if (paymentRequest) {
      const onPaymentMethod = async (ev) => {
        console.log(ev.paymentMethod.id);
        setPaymentMId(ev.paymentMethod.id);
        setPayType("applePayGooglePayPaymentMethodId");
        setGpaySubmit(1);
        setPaymentType(8); // payment type 1,8,7,9
      };

      paymentRequest.on("paymentmethod", onPaymentMethod);

      return () => {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      };
    }
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      const onPaymentMethod = async (event) => {
        const updatedFinalAmount = Math.round(finalTotalValue * 100);
        const updatedPaymentRequest = { ...paymentRequest };
        updatedPaymentRequest.total.amount = updatedFinalAmount;

        // Implement logic to handle the updated payment request
        // For example:
        // const paymentMethod = await stripe.createPaymentMethod({ payment_method: event.paymentMethod.id });

        // You might also need to attach new event listeners for updated payment requests
      };

      paymentRequest.on("paymentmethod", onPaymentMethod);

      return () => {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      };
    }
  }, [paymentRequest, finalTotalValue, stripe]);

  useEffect(() => {
    const discountJSON = localStorage.getItem("discount");
    const discount = discountJSON ? JSON.parse(discountJSON) : "";
    setParsedDiscount(discount);
  }, [discountStatus]);

  useEffect(() => {
    const discountJSON = localStorage.getItem("discount");
    const discount = discountJSON ? JSON.parse(discountJSON) : "";
    let finalTotalValue =
      parseFloat(subTotal) +
      parseFloat(deliveryCharge) +
      parseFloat(bagCharge) +
      parseFloat(serviceCharge) +
      parseFloat(driverTipValue);

    if (discount?.appliedDiscount) {
      finalTotalValue -= parseFloat(discount.appliedDiscount);
    }

    localStorage.setItem("finalTotal", finalTotalValue.toFixed(2));
    setFinalTotal(finalTotalValue.toFixed(2));
    setFinalTotalValue(
      (parseFloat(finalTotalValue) + parseFloat(roundedValue)).toFixed(2)
    );
  }, [
    subTotal,
    parsedDiscount,
    deliveryCharge,
    bagCharge,
    serviceCharge,
    driverTipValue,
    roundedValue,
  ]);
  // scroll Fixed Top Start
  const [sidebarTop, setSidebarTop] = useState(undefined);
  const [cartItemsData, setcartItems] = useState([]);

  useEffect(() => {
    const updateSidebarTop = () => {
      const chatEl = document.querySelector(".cart-page");
      setSidebarTop(chatEl.getBoundingClientRect().top);
    };

    updateSidebarTop();
    window.addEventListener("resize", updateSidebarTop);
    return () => {
      window.removeEventListener("resize", updateSidebarTop);
    };
  }, []);

  useEffect(() => {
    const isSticky = (e) => {
      const chatEl = document.querySelector(".cart-page");
      const scrollTop = window.scrollY;
      chatEl.classList.toggle("is-sticky", scrollTop >= sidebarTop - 96);
    };

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);
  // scroll Fixed Top End

  useEffect(() => {
    let localStorageData = JSON.parse(localStorage.getItem("cart"));
    setcartItems(localStorageData);
  }, [localStorage.getItem("cart")]);

  //status selector
  const placeOrder_status = useSelector(
    (state) => state.placeorderStatusReducer.placeorder
  );
  const [status, setStatus] = useState(false);
  useEffect(() => {
    setStatus(placeOrder_status);
  }, [placeOrder_status]);

  const PlacewalletOrder = async (event) => {
    event.preventDefault();
    setPaySubmit(9);
  };

  const navigate = useNavigate();
  const GoBackFunction = () => {
    navigate(-1);
  };
  // ! page width
  const [width, setWidth] = useState(window.innerWidth);
  // const breakpoint = 767;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  // ! page width

  const HandleClick = async () => {
    let addressData = null;

    if (isLoggedIn === "true") {
      addressData = loginAddress;
    } else {
      addressData = guestAddress;
    }

    if (!addressData) {
      setAddressPopupshow(true);
    } else {
      setButtonstatus(true);
      dispatch(PlaceCardStatus(true));

      if (!stripe || !elements) {
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(
          CardExpiryElement,
          CardNumberElement,
          CardCvcElement
        ),
      });

      if (error) {
        scrollToSection();
        setFooterBtn(true);
        console.log(error);
        setCardErrorMessage(error.message);
        dispatch(PlaceCardStatus(false));
        setButtonstatus(false);

        return;
      } else {
      }

      setPaymentMId(paymentMethod.id);
      setPayType("paymentMethodId");
      setPaySubmit(1);
    }
  };
  //update total payment value
  const handlePayment = async () => {
    try {
      // Use the updated payment request to start the payment flow
      if (paymentRequest) {
        const paymentResult = await paymentRequest.show();
        // Handle payment result as needed
      }
    } catch (error) {
      // Handle errors
    }
  };
  const buttonStyles = {
    paymentRequestButton: {
      height: "200px",
      /* Other styles */
    },
  };


  // add style google & apply pay button
  const options = {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: 'default',
        // One of 'default', 'book', 'buy', or 'donate'
        // Defaults to 'default'
        theme: 'dark',
        // One of 'dark', 'light', or 'light-outline'
        // Defaults to 'dark'
        height: '40px',
        borderRadius:'32px',
        margin: '25px',
        // Defaults to '40px'. The width is always '100%'.
      },
    }
  }
    
  return (
    <>
      <div className="cart-page" name="cart-canva-btn-heading">
        <div className="header">
          <h3>Your order from</h3>
          <h4 className="res-name">
            {stripepayment?.message?.clientDetail?.name}
          </h4>
        </div>
        {!status ? (
          <>
            {paymentType == 1 ? (
              <>
                <button
                  className="order-btn"
                  disabled={buttonstatus ? true : false}
                  onClick={HandleClick}
                >
                  {buttonstatus ? (
                    <Spinner animation="border" size="sm" variant="success" />
                  ) : (
                    "Place your " + getOrderMode + " order"
                  )}
                </button>
              </>
            ) : paymentType == 9 ? (
              <>
                <button
                  className="order-btn"
                  onClick={PlacewalletOrder}
                  disabled={walletstatus == 0}
                >
                  Place your wallet order
                </button>
              </>
            ) : (
              <>
                <button className="order-btn" onClick={HandleClick}>
                  Place your {getOrderMode} order
                </button>
              </>
            )}
          </>
        ) : (
          <></>
        )}
        <div className="order-details">
          <div className="order-table">
            <Table>
              <tbody>
                <>
                  {cartItemsData &&
                    cartItemsData.map((ListItem, index) => (
                      <>
                        <tr key={index}>
                          <td className="count">{ListItem.count}</td>
                          <td className="itemname">{ListItem.itemName}</td>
                          <td className="price">£{ListItem.price}</td>
                        </tr>
                      </>
                    ))}
                </>
              </tbody>
            </Table>
          </div>
          <div className="sub-total">
            <p>
              <span colSpan={2}>Items Subtotal</span>
              <span>£{subTotal}</span>
            </p>
          </div>

          <OfferCode
            setParsedDiscount={setParsedDiscount}
            parsedDiscount={parsedDiscount}
            setDiscountStatus={setDiscountStatus}
          />
          {/* {width > 767 && <FKPlus />} */}

          <div className="total-details">
            {parsedDiscount ? (
              <>
                <p>
                  <span className="label">Discount</span>{" "}
                  <span className="price">
                    -£{parsedDiscount.appliedDiscount}
                  </span>
                </p>
              </>
            ) : null}
            <p>
              <span className="label">Bag Charge</span>{" "}
              <span className="price">£{bagCharge}</span>
            </p>
            <p>
              <span className="label">Service Charge</span>{" "}
              <span className="price">£{serviceCharge}</span>
            </p>
            {getOrderMode === "Delivery" && (
              <>
                <p>
                  <span className="label">Delivery Charge</span>{" "}
                  <span className="price">£{deliveryCharge}</span>
                </p>
              </>
            )}
            {roundedValue !== "0" && roundedValue !== "0.00" && (
              <>
                <p>
                  <span className="label">Round Up</span>{" "}
                  <span className="price">£{roundedValue}</span>
                </p>
              </>
            )}
            {driverTipValue !== "0" && driverTipValue !== "0.00" && (
              <>
                <p>
                  <span className="label">
                    {getOrderMode === "Delivery" ? "Driver Tip" : "Donation"}
                  </span>
                  <span className="price">£{driverTipValue}</span>
                </p>
              </>
            )}
          </div>
          <div className="total-price">
            <span>Total</span>
            <span>£{finalTotalValue}</span>
          </div>
        </div>
        <div className="back-to-menu">
          <button onClick={GoBackFunction}>
            <MdArrowBackIosNew /> Back to menu
          </button>
        </div>
        <div className="order-place">
          {width <= 767 && !status ? (
            <>
              {paymentType == 1 ? (
                <>
                  <button
                    disabled={footerBtn?true:buttonstatus ? true : false}
                    onClick={HandleClick}
                  >
                    {buttonstatus ? (
                      <Spinner animation="border" size="sm" variant="success" />
                    ) : (
                      "Place your " + getOrderMode + " order"
                    )}
                  </button>
                </>
              ) : paymentType == 9 ? (
                <>
                  <button
                    onClick={PlacewalletOrder}
                    disabled={walletstatus == 0}
                  >
                    Place your wallet order
                  </button>
                </>
              ) : paymentType == 8 ||paymentType == 7?(
                  <>
                
                    <div className="payment-btn">
                          {paymentRequest ? (
                      <>
                        
                        <PaymentRequestButtonElement
                          options={options}
                          onClick={handlePayment}

                        />
                        {/* <p>Payment link: {paymentMId}</p>

                        <p>{paymentMId}</p>
                        <p>{gpaySubmit}</p> */}
                        <CreatePaymentMethod
                          paymentRequest={paymentRequest}
                          onPaymentMethodReceived={(paymentMethodId) =>
                            setPaymentMethodId(paymentMethodId)
                          }
                          updateJsonData={updateJsonData}
                          setPaymentMId={setPaymentMId}
                          setPayType={setPayType}
                          setPaySubmit={setPaySubmit}
                        />
                        
                      </>
                    ) : (
                      <>
                      
                          {paymentLoad?(<>
                          <div className="spinner-container" style={{alignItems:"center"}}>
                          <Spinner animation="border" size="sm" variant="success" />
                          </div></>
                            ):(<>
                           
    {paymentType === 8 ? (
      <><p>Sorry, google-pay is not available on your device</p></>
    ) : paymentType === 7 ? (
      <><p>Sorry, Apple Pay is not available on your device</p></>
    ) : (
      <><p>Something Went Wrong</p></>
    )}
 
                          </>)}
                       
                      </>
                    )}
                    </div>
                
                  </>

              ) :(
                <>
                  <button onClick={HandleClick}>
                    Place your {getOrderMode} order
                  </button>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <div className="terms-text">
            <p>
              Agree to our <Link to="/privacy">policies</Link> by placing orders
            </p>
            {/* <p>
              By placing your order, you agree to Fusion Kitchen’s{" "}
              <Link to="/general-terms">terms </Link>of use and{" "}
              <Link to="/privacy">privacy policy</Link>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Cart;
function CreatePaymentMethod(props) {
  const {
    chargesdetail,
    stripepayment,
    paymentRequest,
    PlaceOrderJson,
    stripe,
    elements,
    placeOrderaApiJson,
    payType,
    setPayType,
    paymentSva,
    setPaymentSva,
    paymentMId,
    setPaymentMId,
    paymentIntentId,
    setPaymentIntentId,
    paymentType,
    setPaySubmit,
  } = useContext(CheckoutContext);
}
const OfferCode = (props) => {
  function removeOffer() {
    removeDiscount();
    props.setDiscountStatus(1);
  }

  return (
    <>
      {props.parsedDiscount ? (
        <>
          <div className="offer-code">
            <img src={OfferIceon} alt="" />
            <p>
              “<span>{props.parsedDiscount.discount}</span> applied”
            </p>
            <button onClick={removeOffer}>Remove</button>
          </div>
        </>
      ) : null}
    </>
  );
};

export const FKPlus = (props) => {
  const options = [
    { value: "Buy 1 Month Plan", label: "Buy 1 Month Plan" },
    { value: "Buy 3 Month Plan", label: "Buy 3 Month Plan" },
    { value: "Buy 6 Month Plan", label: "Buy 6 Month Plan" },
    { value: "Buy 1 Year Plan", label: "Buy 1 Year Plan" },
  ];
  return (
    <>
      <div className="fk-plus">
        <div className="plus-header">
          <div className="plus-icon">
            <img src={FKplusBG} alt="" />
          </div>
          <p>
            free Delivery on all orders with{" "}
            <span>
              <img src={FKplus} alt="" />
            </span>
          </p>
        </div>
        <div className="content">
          <h3>
            you can save <span>£3.59</span> extra on <br /> this order!
          </h3>
          <hr />
          <div className="footer-box">
            <Select
              options={options}
              isClearable
              classNamePrefix="plan"
              className="fk-plan"
              placeholder="Choose...."
              defaultValue={options[0]}
              isSearchable={false}
            />
            <button>Try Now</button>
          </div>
        </div>
      </div>
    </>
  );
};
