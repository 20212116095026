
import Becomepartnerqr from "./becomepartner-qr";
import leftloginimg1 from "./img/leftimg-login.png";
import centerimg from "./img/center-img.png";
import React, { useState } from "react";

import groupicon from "./img/groupicon.png";
import "./css/becomelogin.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { becomePartner } from "../../actions/static/becomepartnerAction";
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>



  const Becomepartnerlogin = () => {
    const [showQR, setShowQR] = useState(false);
    
    
    const handleSendLink = () => {
      setShowQR(true);
    };

      

  

    return (
      <section className=" becomepartner-login1">
        <div className="row" style={{ height: "90%" }}>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-1" id="tops">
            <div className="left-image1">
              <img src={leftloginimg1} alt="line-login-image" />
              <div className="video-img">
                <iframe
                  src="https://www.youtube.com/embed/fYOEHz7lZ2Q?si=WBrj9WkEv2oaysWB"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  width="60%" 
                  height="80%"
                  muted
                  autoPlay
                  playsInline
                 
                ></iframe>
              </div>

              {/* <div className="pos-icon">
                <img src={groupicon} alt="Play Video" />
              </div> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 pt-4" id="second-half">
            <div className="semi">
              <div class="semi-circle"></div>
            </div>
            <div className="content-half">
              <div className="form-log">
                <h1 className="form-head">Start selling on Fusion Kitchen</h1>
                <p>
                  Welcome! Let’s get you set up. It should only take a few
                  minutes
                </p>
              </div>

              <div className="forms">
                <div className="material-textfield mt-4">
                  <input placeholder=" " type="text" id="place" />
                  <label>Name</label>
                </div>
                <div className="material-textfield mt-4">
                  <input placeholder=" " type="text" id="place" />
                  <label>Email</label>
                </div>
                <div className="material-textfield mt-4">
                  <input placeholder=" " type="text" id="place" />
                  <label>Phone</label>
                </div>
              </div>

              <div className="check-bo mt-4">
                <Form.Check aria-label="option 1" />
                <p>By Continuing you agree to our privacy policy</p>
              </div>

              <div className="buts">
                <button onClick={handleSendLink} size="lg">
                  Send link
                </button>
              </div>
            </div>

            <div className="semi1 mt-2">
              <div class="semi-circle1"></div>
            </div>
          </div>
        </div>

        {showQR && (
          <div className="qr">
            <Becomepartnerqr />
          </div>
        )}
      </section>
    );
  
  };


export default Becomepartnerlogin;
