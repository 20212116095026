// *******~ Import ~******** //
// React
import React, { useState, useEffect, useContext, lazy } from "react";
// Assets
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import NavDropdown from "react-bootstrap/NavDropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// Components
import { ThemeSetterTwo } from "../theme/components/themeshetter";
import ThemeContext from "../theme/components/contexts/themecontexts";
import MenuBtn from "./menubtn/menubtn";
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';
import partnerlogin from "../../pages/become-partner/becomepartner-login";
// import SignIn from "../../pages/sign-in/sign-in";
// CSS
import "./css/header.scss";
// Images
// import LogoLight from "./img/FK-Half-White.svg";
// import LogoDark from "./img/Fk-logo.svg";

import LogoDark from "./img/fk_partnerLogo.png";
// Responsive Img
// Responsive Img
// import WalletImg from "./img/wallet.svg";
import HomeIcon from "./img/homeicon.svg";
import UpcomingOrders from "./img/upcoming-orders.svg";
import MyOrdersIcon from "./img/myorders.svg";
import MapIcon from "./img/maps.svg";
import ProfileIcon from "./img/profile.svg";
import FavoriteIcon from "./img/favorite.svg";
import WalletIcon from "./img/wallet-menu.svg";
import FKPlus from "./img/fkplus.svg";
import ReferIcon from "./img/refer.svg";
import PerksIcon from "./img/perks.svg";
import HelpIcon from "./img/help-web.svg";
// Icons
import { FaUserCircle } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";
import { MdAccountBalanceWallet } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { setLoggedIn } from "../../actions/login/authssoActions";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { WiMoonAltWaningCrescent4, WiDaySunny } from "react-icons/wi";
import { GiShoppingBag } from "react-icons/gi";
import { Link as ScrollLink } from "react-scroll";
import { Helmet } from "react-helmet";
// *******~ Import ~******** //

// login code
import { checkUserSession } from "../../utils/sessionUtils";
// redux api
import { connect } from "react-redux";
import { logout } from "../../actions/login/authActions";
import { restaurantDetailApi } from "../../api/menu/restaurantDetailApi";
import Loadable from "../../router/loadable";
// lazy

const SignIn = Loadable(lazy(() => import("../../pages/sign-in/sign-in")));
const Header = ({ isLoggedIn, userData, logout }) => {
  const location = useLocation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      userData &&
      userData.status &&
      userData.customerId &&
      (userData.login_type == 1 || userData.login_type == 2)
    ) {
      reduxDispatch(setLoggedIn());
      // navigate('/');
    }
  }, [userData]);
  const { pathname } = useLocation();
  const { theme } = useContext(ThemeContext);

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 991;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  const menus = [
    {
   
      name: "Help",
      url: "/help",
    },
    // {
    //   name: "Login",
    //   url: "/",
    // },
  ];

  const handleLogout = () => {
    // Perform any additional logout logic here
    logout();
    if (location.pathname.includes("/checkout")) {
      navigate(-1);
    } else if (!location.pathname.includes("/menu")) {
      navigate("/");
    }
  };
  return (
    <>
      {pathname.endsWith("/menu") ||
      pathname.endsWith("/about") ||
      pathname.endsWith("/review") ||
      pathname.endsWith("/checkout") ? (
        <>
          <Helmet>
            <style type="text/css">{`
        @media only screen and (min-width: 320px) and (max-width: 767px) {
          nav.main-header .logo img{
            visibility:hidden;
          }
        }
    `}</style>
          </Helmet>
        </>
      ) : null}

      <Navbar collapseOnSelect expand="lg" sticky="top" className="main-header">
        <Container>
          <div className="logo">
            {theme === "light" ? (
              <>
                <Nav.Link as={Link} to={"/"} eventKey={0}>
                  <img src={LogoDark} alt="" />
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to={"/"} eventKey={0}>
                  <img src={LogoDark} alt="" />
                </Nav.Link>
              </>
            )}
          </div>

          {pathname.endsWith("/checkout") ? (
            <ScrollLink
              eventKey="0"
              className="cart-canva-btn-heading"
              activeClass="active"
              to="cart-canva-btn-heading"
              spy={true}
              offset={-75}
              smooth={true}
              delay={0}
              duration={0}
            >
              <span className="btn-data">
                <span className="count">{localStorage.getItem("cartQty")}</span>{" "}
                <GiShoppingBag />
              </span>
            </ScrollLink>
          ) : (
            <ThemeButton clsname="mobile-button-theme" />
          )}

          <MenuBtn />
          {width > breakpoint ? (
            <>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="buts-header"
              >
                <Nav>
                  {/* {menus.map((menu, index) => (
                    <Nav.Link
                      as={Link}
                      key={index}
                      eventKey={index}
                      to={menu.url}
                    >
                      {menu.name}
                    </Nav.Link>
                  ))}

                 
                  {isLoggedIn ? (
                    <NavDropdown title={`Hi ${userData.fname}`}>
                      <NavDropdown.Item as={Link} eventKey="5" to="/myaccount">
                        <FaUserCircle /> My Account
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        eventKey="6"
                        as={Link}
                        to="/myaccount/wallet"
                      >
                        <MdAccountBalanceWallet /> Wallet
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="6" as={Link} to="/myaccount">
                        <MdFavorite /> Favourite
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={handleLogout}
                        eventKey="6"
                        as={Link}
                        to={!location.pathname.includes("/menu") ? "/" : "#"}
                      >
                        <IoMdExit /> Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <SignIn />
                  )} */}
                  <Link to="partnerlogin">
                    <Button variant="outline-secondary">Create account</Button>
                  </Link>

                  <Button variant="outline-secondary"> Sign In</Button>{" "}
                </Nav>
              </Navbar.Collapse>
              <ThemeButton />
            </>
          ) : (
            <>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="mobile-header"
              >
                {isLoggedIn && (
                  <>
                    <p className="top-name">{`Hi ${userData.fname}`}</p>
                    <Nav>
                      {[
                        {
                          name: "Home",
                          img: HomeIcon,
                          url: "/",
                        },
                        {
                          name: "Upcoming Orders",
                          img: UpcomingOrders,
                          url: "/myorder",
                        },
                        {
                          name: "My Orders",
                          img: MyOrdersIcon,
                          url: "/myorder",
                        },
                        {
                          name: "Profile",
                          img: ProfileIcon,
                          url: "/profile",
                        },
                        {
                          name: "Favourite",
                          img: FavoriteIcon,
                          url: "/profile",
                        },
                        {
                          name: "Address",
                          img: MapIcon,
                          url: "/addressbook",
                        },
                        {
                          name: "Wallet",
                          img: WalletIcon,
                          url: "/wallet",
                        },
                        {
                          name: "Help",
                          img: HelpIcon,
                          url: "/help",
                        },
                      ].map((data, index) => (
                        <Nav.Link
                          key={index}
                          eventKey={index}
                          as={Link}
                          to={data.url}
                        >
                          <Image src={data.img} />
                          {data.name}
                        </Nav.Link>
                      ))}
                    </Nav>
                  </>
                )}

                {!isLoggedIn && (
                  <>
                    <Nav>
                      {[
                        {
                          name: "FK+",
                          img: FKPlus,
                          url: "/",
                        },
                        {
                          name: "Refere a Friend",
                          img: ReferIcon,
                          url: "/",
                        },
                        {
                          name: "Perks",
                          img: PerksIcon,
                          url: "/",
                        },
                        {
                          name: "Help",
                          img: HelpIcon,
                          url: "/help",
                        },
                      ].map((data, index) => (
                        <Nav.Link
                          key={index}
                          eventKey={index}
                          as={Link}
                          to={data.url}
                        >
                          <Image src={data.img} />
                          {data.name}
                        </Nav.Link>
                      ))}
                    </Nav>
                  </>
                )}
                <div className="not-log">
                  {isLoggedIn ? (
                    <>
                      <p>
                        Not {userData.fname}? &nbsp;
                        <p onClick={handleLogout}>Logout</p>
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Dont’ have a account? &nbsp; <SignIn />
                      </p>
                    </>
                  )}
                </div>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.userdata.isLoggedIn,
    userData: state.userdata.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

//export default connect(mapStateToProps)(Header);
export default connect(mapStateToProps, mapDispatchToProps)(Header);
//export default Header;

function ThemeButton(props) {
  const { theme } = useContext(ThemeContext);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleScrollLinkClick = () => {
    setPopoverVisible(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      setPopoverVisible(false);
    };

    // Add the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <OverlayTrigger
        trigger="click"
        key="auto"
        placement="bottom"
        rootClose={true}
        rootCloseEvent={"click"}
        show={popoverVisible}
        onToggle={setPopoverVisible}
        overlay={
          <Popover
            id="theme-head-pop"
            className={`theme-head ${theme === "dark" ? "dark-theme" : ""}`}
          >
            <Popover.Body>
              <div className="category-div" onClick={handleScrollLinkClick}>
                <ThemeSetterTwo />
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <span className={`header-theme-btn ${props.clsname}`}>
          {theme === "dark" ? <WiMoonAltWaningCrescent4 /> : <WiDaySunny />}
        </span>
      </OverlayTrigger>
    </>
  );
}
