// // *******~ Import ~******** //
// // React
// // Assets
import { lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// Components
import GoTop from "./common/gototop/gototop";
import ScrollToTop from "./common/scrolltop/scrolltop";
import Routing from "./router/router";
import Header from "./common/header/header";
// import Footer from "./common/footer/footer";
const Footer = lazy(() => import("./common/footer/footer"));
// CSS
// Images
// Icons
// *******~ Import ~******** //
export default function Main(params) {
  return (
    <>
      <Router basename={"/"}>
        
        <GoTop />
        <ScrollToTop />
        <Header/>
        
        <Routing stripe={params.stripe} />
        <Footer />
      </Router>
    </>
  );
}
// two

// import React, { lazy, Suspense } from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import GoTop from "./common/gototop/gototop";
// import ScrollToTop from "./common/scrolltop/scrolltop";
// import Routing from "./router/router";
// import Header from "./common/header/header";
// import ListSkeleton from "./pages/listpage/listskeleton";

// const Footer = lazy(() => import("./common/footer/footer"));

// export default function Main(params) {
//   return (
//     <>
//       <Router basename={"/"}>
//         <GoTop />
//         <ScrollToTop />
//         <Header />

//         <Routing stripe={params.stripe} />

//         <Suspense
//           fallback={
//             <div>
//               <ListSkeleton />
//             </div>
//           }
//         >
//           <Footer />
//         </Suspense>
//       </Router>
//     </>
//   );
// }

// three






