import React from 'react';

import "./css/partnerqr.scss";

import qrimage from "./img/image 56.png";

import Button from "react-bootstrap/Button";

import GooglePlay from "./img/gplay.svg";
import AppleStore from "./img/apple.svg";
import PlayStoreQRCode from './img/android_a.png';
import AppStoreQRCode from './img/ios_b.png'; 

const getOS = () => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf("Mac") !== -1) {
    return "Mac";
  } else if (userAgent.indexOf("Windows") !== -1) {
    return "Windows";
  }
  // Add more conditions for other operating systems if needed
  return "Unknown";
};
  const renderQRCode = () => {
    const os = getOS();

    if (os === "Mac") {
      return <img src={AppStoreQRCode} alt="App Store QR Code" />;
    } else if (os === "Windows") {
      return <img src={PlayStoreQRCode} alt="Play Store QR Code" />;
    }
    // Render a default QR code or a message for other operating systems
    return <p>QR Code not available for your OS.</p>;
  };

const Becomepartnerqr = () => {
  return (
    <section className="partnerqr" style={{ height: "130%" }}>
      <div className="container">
        <div className="row flex-column-reverse flex-sm-row">
          <div className="col-lg-6 col-md-6 col-sm-6" id="one">
            <div className="list-qr">
              <ul>
                <div className="head-qr">
                  <h2>Finish on the mobile app</h2>
                </div>
                <div className="lists-qr">
                  <li>Scan the QR code to download the Fusion app 👉</li>
                  <li>Choose “I’m new to Fusion” in the application.</li>
                  <li>Use the same email you’ve used here to log in.</li>
                </div>

                <div className="g-icon">
                  <li style={{ listStyleType: "none" }}>
                    <a href="https://play.google.com/store/apps/details?id=in.fusionpos.fusionpartner">
                      <img src={GooglePlay} fluid />
                    </a>
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <a href="https://apps.apple.com/in/app/fusion-partner/id1502807167">
                      <img src={AppleStore} fluid />
                    </a>
                  </li>
                </div>

             
              </ul>
            </div>
          </div>

          <div
            className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center justify-content-center"
            id="two"
          >
            <div className="qrimg">
              {renderQRCode()}
              {/* <img src={qrimage} alt="qrcode" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Becomepartnerqr
